.tag-banners {
  &__items {
    height: max-content;
  }

  &.primary {
    .title {
      color: var(--primary);
    }

    &-contrast {
      background-color: var(--gray-400);

      &-darker {
        background-color: var(--gray-300) !important;
      }
    }
  }

  &.secondary {
    .title,
    button.LinkInternal {
      color: var(--purple) !important;
    }

    &-contrast {
      background-color: var(--gray-400);

      .title,
      button.LinkInternal {
        color: var(--purple) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title,
        button.LinkInternal {
          color: var(--purple) !important;
        }
      }
    }
  }

  &.tertiary {
    .title,
    button.LinkInternal {
      color: var(--green-dark-100) !important;
    }

    &-contrast {
      background-color: var(--gray-400);

      .title,
      button.LinkInternal {
        color: var(--green-dark-100) !important;
      }

      &-darker {
        background-color: var(--gray-300) !important;

        .title,
        button.LinkInternal {
          color: var(--green-dark-100) !important;
        }
      }
    }
  }
}
