@import '../../../styles/sizes';

.tag-banner {
  border-radius: 16px;
  overflow: hidden;
  margin: 8px 0;
  transition: box-shadow 0.2s ease;
  border: none;

  &:focus-within {
    outline: 2px solid blue;
  }

  @media screen and (min-width: $grid-md) {
    height: 100%;
    max-height: -webkit-fill-available;
  }

  &:hover {
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &__link {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .card-image-body {
    max-height: 130px;
    overflow: hidden;
    width: 100%;

    @media screen and (min-width: $grid-sm) {
      max-height: 192px;
      flex: 1 0 auto;
    }

    img {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      width: 100%;
      height: 100%;
      min-height: 192px;
      object-fit: cover;
      max-height: 130px;
      padding: 0;
      margin: 0;

      @media screen and (min-width: $grid-sm) {
        max-height: 192px;
      }
    }
  }

  .card-image-content {
    min-height: 57px;
    padding: 16px;
    transition: background 200ms ease;
    font-family: 'UnimedSans-2020-SemiBold', Arial, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0px 0px 16px 16px;
    width: 100%;

    @media screen and (min-width: $grid-sm) {
      height: 100%;
    }

    > div {
      display: flex;
      width: 100%;
    }

    i:before {
      font-size: 1.5rem;
    }
  }
}
